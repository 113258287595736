@import "variables";
body {
  margin: 0;
  font-family: 'M PLUS Rounded 1c', -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
  'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
  sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-size: 18px;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
  monospace;
}

a:visited {
  color: $color-black;
}

.section {
  flex: auto;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  min-height: 100px;
  width: 100%;
  border-bottom: $color-black solid 2px;
  
  &-title {
    font-size: 40px;
    font-weight: bold;
    color: $color-title;
    margin: 12px 0;
  }

  .section-wrapper {
    flex: 1 1 auto;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    width: 100%;
    max-width: 800px;
  }
  
  &-content {
    flex: 1 1 auto;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-start;
    width: 100%;
    max-width: 1200px;
    padding: 0 16px;
  }
  
  &.alternative {
    background-color: $section-background-alternative;
  }
}


