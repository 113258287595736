@import 'src/styles/variables';

.artist {
  color: $color-white;
  //font-weight: 500;
  font-size: 1.1em;
  
  &-image img {
    width: 140px;
    height: 140px;
    max-height: 140px;
    max-width: 140px;
    border-radius: 50%;
    aspect-ratio: 1/1;
  }
}