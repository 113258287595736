@import "src/styles/variables";

.action-button {
  display:block;
  width:300px;
  height:60px;
  line-height:24px;
  border: 0;
  border-radius: 32px;
  color:$color-white;
  background: $color-black;
  text-align:center;
  text-decoration:none;
  font-size: 24px;
  font-weight: 600;
}
.action-button:hover {
  color: $color-purple;
  cursor: pointer;
}