@import "../../styles/variables";


.recommender {
  display: flex;
  flex-direction: column;
  padding: 40px 0 0 0;
  
  &.section {
    background-color: $color-green-dark;
    border-bottom: 0;
  }
  
  
  &-content {
    display: flex;
    flex-direction: column;
    flex: 1;
    align-items: center;
    //flex-wrap: wrap;
    justify-content: center;
    min-width: 380px;
    width: 100%;
    
    .recommender-searcher {
      flex: 1 1 auto;
      display: flex;
      flex-direction: row;
      gap: 16px;
      width: 700px;
      max-width: calc(100vw - 32px);
    }
    
    .recommender-left {
      flex: 1 0 100px;
    }
    .recommender-slider {
      flex: 1 0 100px;
      display: flex;
      flex-direction: row;
      justify-content: center;
      align-items: center;
      max-height: 80px;
      //margin-top: -100px;
      //max-width: 300px;
      //order: 2;
    }
    .recommender-right {
      flex: 1 0 100px;
    }
  //  If width is less then 560px change order of elements
    @media (max-width: 560px) {
      //flex-direction: column;
      .recommender-left {
        padding-right: 16px;
      }
      .recommender-slider {
        padding-top: 16px;
        //margin: 0;
      }
      .recommender-right {
      }
    }
  }
  
  
  &-playlist {
    padding: 32px;
    background-color: $section-background-alternative;
  }
}