@import "src/styles/variables";

.search {
  padding-bottom: 12px;
  
  input {
    max-width: 200px;
    border-radius: 20px;
    @media (max-width: 560px) {
      max-width: 160px;
    }
  }
  .suggestions-list {
    list-style: none;
    padding: 0;
    max-height: 160px;
    overflow-y: auto;
    max-width: 250px;
  }
  .suggestion {
    background-color: $color-pink;
    padding: 10px;
    color: $color-black-almost;
    cursor: pointer;
  }
  .suggestion:hover {
    background-color: $color-yellow;
  }
}