@import "src/styles/variables";

.muyaio-social {
  display: flex;
  flex-direction: row;
  justify-content: center;
  gap: 16px;
  
  .social-button {
    background-color: $color-green-light;
    color: $color-black;
  }
}