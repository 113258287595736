@import "src/styles/variables";

.recommendation {
  padding: 16px 0 0 0;
  
  &.section {
    background-color: $color-green-dark;
  }

  &-playlist {
    //min-height: calc(100vh - 64px);
    //min-width: calc(100vw - 64px);;
    width: calc(100vw - 32px);
    max-width: 800px;
    min-height: 400px;
  }

  &-share {
    display: flex;
    flex-direction: column;
    padding-top: 100px;
    width: 100%;
    // background image
    // background-image: url(backgroundImage);
    background-repeat: no-repeat;
    //background-attachment: fixed;
    background-position: bottom;
    background-color: $color-green-dark;
    background-size: contain;
    @media (max-width: 620px) {
      background-size: auto;
    }

    &-title {
      color: $color-white;
      padding-bottom: 8px;
    }

    &-buttons {
      display: flex;
      flex-direction: row;
      justify-content: center;
      align-items: center;
      padding: 8px 0;
      gap: 8px;
      
      .recommendation-share-button {
        //border-radius: 50%;
        //width: 32px;
        //height: 32px;
      }
    }
  }
}