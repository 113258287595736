@import 'src/styles/variables';

.your {
  padding: 32px 0 8px 0;

  &.section {
    background-color: $color-green-dark;
  }

  .section-content {
    padding: 0;
  }

  &-description {
    color: $color-white;
    padding: 16px 0;
  }

  &-faltas-wrapper {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    width: 100%;
    padding: 32px 0 8px 0;

    .your-faltas-left, .your-faltas, .your-faltas-right {
      flex: 1 1 33%;
      margin-top: auto;
    }

    @media (max-width: 560px) {
      .your-faltas-right-img {
        display: none;
      }
    }

    //&-left {
    //  padding-left: 16px;
    //  text-align: left;
    //}
    //&-right {
    //  padding-right: 16px;
    //  text-align: right;
    //}
  }

  .action-button {
    background-color: $color-pink;
    border: 2px solid $color-black;
  }

  &-button {
    padding-bottom: 16px;
  }

  &-pre-footer {
    width: 100%;
    text-align: left;
    
    @media (max-width: 560px) {
      .your-pre-footer-img {
        display: none;
      }
    }
  }

  &-footer {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    width: 100%;
    padding: 32px 0 8px 0;
    font-size: 14px;

    @media (max-width: 560px) {
      font-size: 11px;
    }

    &-left, &-middle, &-right {
      flex: 1 1 33%;
      margin-top: auto;
    }

    &-left {
      padding-left: 16px;
      text-align: left;
    }

    &-right {
      padding-right: 16px;
      text-align: right;

    }


    .social-button {
      background-color: $color-purple;
    }
  }
}