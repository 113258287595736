@import "src/styles/variables";
$size: 40px;
.social-button {
  display:flex;
  justify-content: center;
  align-items: center;
  width:$size;
  height:$size;
  line-height:$size;
  border: 0;
  border-radius: 50%;
  color:$color-white;
  background: $color-black;
  text-align:center;
  text-decoration:none;
}
.social-button:hover {
  color: $color-white;
  cursor: pointer;
}