@import "src/styles/variables";

.slider {
  min-width: 300px;
  @media (max-width: 560px) {
    min-width: 300px;
    padding-top: 16px;
    padding-bottom: 16px;
  }

  // Remove default border on focused elements
  *:focus {
    outline: none;
  }
}

.horizontal-slider {
  width: 100%;
  max-width: 500px;
  height: 8px;
  //border: 1px solid grey;
  border-radius: 111px;
  background-color: $color-purple;
  display: flex;
  flex-direction: row;
  align-items: center;
}

.slider-thumb {
  height: 30px;
  line-height: 30px;
  width: 30px;
  text-align: center;
  background-color: $color-white;
  // color white, so that you don't see the text.
  color: $color-white;
  border-radius: 50%;
  cursor: grab;
  border: 6px solid $color-yellow;
}

//.slider-thumb:active {
//  cursor: grabbing;
//  background-color: #e85119;
//}

.slider.active {
  background-color: #ff0000;
}

.slider-track {
  position: relative;
  background: #ddd;
}
.slider-track.example-track-1 {
  background: #f00;
}
.slider-track.example-track-2 {
  background: #0f0;
}

.slider-mark {
  width: 5px;
  height: 5px;
  border: 0;
  background-color: #fdd0ad;
  cursor: pointer;
  border-radius: 50%;
  vertical-align: middle;
}