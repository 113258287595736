@import 'src/styles/variables';

.video {
  &.section {
    background-color: $color-purple;
    padding-top: 40px;
    padding-bottom: 40px;
    
  }
  
  &-title img {
    max-width: calc(60vw);
  }
  
  &-player {
    width: calc(100vw - 32px);
    height: 100%;
    max-width: 1200px;
  }
  
  iframe {
    width: 100%;
    height: 100%;
    max-height: 100vh;
    aspect-ratio: 16/9;
  }
  
}
