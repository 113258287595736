@import 'src/styles/variables';

.how {
  padding: 32px 0 0 0;

  &.section {
    background-color: $color-yellow;
  }

  .section-wrapper {
    align-items: flex-end;
  }

  .section-content {
    padding: 0;
  }

  &-row {
    display: flex;
    flex-direction: row;
    align-items: stretch;
    flex: 1 1 auto;
    width: 100%;

    @media (max-width: 560px) {
      flex-direction: column;
    }

    .how-left {
      flex: 1 1 30%;
      align-self: center;
      padding: 0 32px 0 0;
      img {
        width: 300px;
        max-width: calc(25vw - 32px);
      }
      @media (max-width: 560px) {
        padding: 0;
        img {
          width: 150px;
          max-width: 150px;
          //max-width: calc(25vw - 32px);
        }
      }
    }

    .how-middle {
      flex: 1 1 50%;
      display: flex;
      flex-direction: column;
      padding-bottom: 70px;

      .how-text {
        flex: 1 1 auto;
        text-align: right;
        padding: 0 16px;
        max-width: 650px;
        
        .how-como {
          font-weight: 300;
        }
      }

      .how-action {
        flex: 0 1 auto;
        display: flex;
        flex-direction: row;
        justify-content: flex-end;
        padding: 32px 0;
      }
      
    }

    .how-right {
      flex: 0 1 20%;
      display: flex;
      flex-direction: column;
      align-items: flex-end;
      justify-content: space-between;
      padding-top: 50px;
      
      .how-right-top {
        align-self: flex-start;
        max-width: 40px;
        margin-left: 40px;
      }  
      
      .how-right-bottom {
        max-width: calc(20vw - 32px);
      }

      @media (max-width: 700px) {
        display: none;
      }
    }


  }





}