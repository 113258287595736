@import 'src/styles/variables';

.about {
  padding: 32px 0 64px 0;
  &.section {
    background-color: $color-pink;
  }
  

  .section-content {
    padding: 0;
  }
  
  &-text {
    text-align: left;
    @media (max-width: 560px) {
      padding: 0 16px;
    }
  }
  
  
  &-row {
    display: flex;
    flex-direction: row;
    align-items: center;

    @media (max-width: 560px) { 
        flex-direction: column;
    }
  }
  
  &-left {
    flex: 1 1 50%;
    padding: 0 32px 0 0;
    @media (max-width: 560px) {
      padding: 0;
      //display: none;
    }
  }
  
  &-image {
    width: 200px;
    min-width: 200px;
    padding: 0 0 0 32px;
    @media (max-width: 560px) {
      padding: 16px 0 0 0;
    }

    img {
      height: 100%;
      width: 100%;
    }
  }
  
  &-share {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    padding: 32px 0;
    gap: 8px;
    width: 100%;
  }

}